import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../Assets/RIT COLLEGE LOGO.png'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [plus, setPlus] = useState(false)
    const [isOpenAcad, setIsOpenAcad] = useState(false);
    const [plusAcad, setPlusAcad] = useState(false)
    const [isOpenCo, setIsOpenCo] = useState(false);
    const [IsOpenCoSubItem, setIsOpenCoSubItem] = useState(false);
    const [plusCo, setPlusCo] = useState(false)
    const [IsOpenFaci, setIsOpenFaci] = useState(false);
    const [plusFaci, setPlusFaci] = useState(false)
    const [IsOpenAct, setIsOpenAct] = useState(false);
    const [plusAct, setPlusAct] = useState(false)


    const [isUnderGraduateOpen, setIsUnderGraduateOpen] = useState(false);
    const [isPostGraduateOpen, setIsPostGraduateOpen] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();

    const [isSticky, setSticky] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };
    const closeDropdown = () => {
        setIsOpen(false);
        setPlus(false);
        setIsOpenAcad(false);
        setPlusAcad(false);
        setPlusCo(false)
        setIsOpenCo(false)
        setIsOpenFaci(false)
    };

    const handleMouseEnterAcad = () => {
        setIsOpenAcad(true);
    };

    const handleMouseLeaveAcad = () => {
        setIsOpenAcad(false);
    };
    const handleMouseEnterUnderGraduate = () => {
        setIsUnderGraduateOpen(true);
    };

    const handleMouseLeaveUnderGraduate = () => {
        setIsUnderGraduateOpen(false);
    };

    const handleMouseEnterPostGraduate = () => {
        setIsPostGraduateOpen(true);
    };

    const handleMouseLeavePostGraduate = () => {
        setIsPostGraduateOpen(false);
    };


    return (
        <div>
            <div className={`${window.innerWidth < 753 ? 'px-2' : 'px-5'} color py-1  d-flex justify-content-between align-items-center`} >
           <div className='my-2 d-flex align-items-center'>
            <i style={{ color: '#FF9800', fontSize:'large' }} className="m-0 p-0 fa-solid fa-envelope"></i>
            <p style={{fontWeight:'600',fontSize: window.innerWidth < 440 ? '12px' : ''}}  className='m-0 px-2'>ritthen30959@yahoo.co.in</p>
             </div> 
             <div className='my-2 d-flex align-items-center'>
            <i style={{ color: '#FF9800', fontSize:'large' }} className="m-0 p-0 fa-solid fa-mobile-screen-button"></i>
            <p style={{fontWeight:'600',fontSize: window.innerWidth < 440 ? '12px' : ''}} className='m-0 px-2'>04172-299502</p>
             </div> 
            </div>


            {/*  Nav 2 */}
            <div className={`${window.innerWidth < 753 ? 'px-2' : 'px-5'} my-2 `}> <div className='d-flex align-items-center justify-content-start mx-1 my-1'>
                <Link to='/'> <img width={window.innerWidth < 753 ? '70px' : '100px'} src={Logo} alt="" /></Link>
                <div className='px-2 d-flex align-items-start justify-content-around  flex-column'>
                    <h2 className='m-0' style={{ fontFamily: "'Roboto', sans-serif", fontSize: window.innerWidth < 753 ? '15px' : '22px', fontWeight: '700', color: '#393185', justifySelf: 'flex-start' }}>RANIPPETTAI <br /> INSTITUTE OF TECHNOLOGY COLLEGE</h2>
                    <p className='m-0' style={{ color: '#393185', fontFamily: "'Roboto', sans-serif", fontSize: window.innerWidth < 753 ? '12px' : '15px' }}>Thenkadappanthangal, Walaja(TK) , Ranipettai- 632513</p></div>
            </div></div>

            {/*  Nav 3 */}

            <nav className={`shadow navbar navbar-expand-md w-100 color ${isSticky ? 'fixed-top' : ''}`} style={{ zIndex: '1', transition: 'ease-in 0.2s', top: '0' }}>
                <div className="container-fluid d-flex flex-column">
                    <div className='d-flex align-items-center justify-content-end w-100 px-2'>
                        <button className="navbar-toggler" style={{ borderColor: 'white'  }} type="button" data-bs-toggle="offcanvas" data-bs-target="#demo">
                            <i className="fa-solid fa-bars" style={{ color: 'white' }}></i>
                        </button>

                        <div className="offcanvas offcanvas-end w-100 !important" id="demo" style={{ flexGrow: '0', background: '#002147' }}>
                            <div className="offcanvas-header">
                                <h1 className="offcanvas-title text-light">MENU</h1>
                                <button type="button  " data-bs-dismiss="offcanvas" className='btn' style={{ background: 'transparent', border: '1px solid white'}}>
                                    <i className="fa-solid fa-xmark" style={{ fontSize: 'large', color: 'white' }}></i>
                                </button>
                            </div>
                            <div className="offcanvas-body" style={{ width: '100%' }}>
                                <ul className="navbar-nav  gap-4" id='OffcanBody' style={{ width: '100%', display: window.innerWidth < 766 ? '': 'flex', alignItems:window.innerWidth < 766 ? '': 'center' }}>
                                    <li className={`nav-item px-2`} type="button" data-bs-dismiss="offcanvas">
                                        <Link className={`Mlist ${location.pathname === '/' ? 'actives' : ''}`} to='/'> <span> Home </span> </Link>
                                    </li>

                                    {/* About us for pc screen  */}
                                    <div
                                        className="dropdown d-none d-sm-block px-2 "

                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave} >
                                        <li
                                            type="button"
                                            className="text-light Mlist "
                                            style={{ background: 'none', border: '0px' }}
                                        >
                                            <span className={`${isOpen ? 'text-warning' : ''}`} style={{ borderTop: isOpen ? '1px orange solid' : null }}>About  <i className="fa-solid fa-angle-down" fontSize="small"></i> </span>
                                            <i classname="fa-solid fa-angle-down" style={{ fontSize: 'large' }}></i>
                                        </li>


                                        <ul
                                            className={`rounded-4 py-0 dropdown-menu ${isOpen ? 'show' : ''}`}
                                            ref={dropdownRef} style={{ MarginLeft: '-10px', border: '0px', background: '#0098fe', color: 'white', minWidth: 'unset', marginTop: '0px' }}
                                        >
                                            <li onClick={closeDropdown} className={`nHover ${location.pathname === '/about-us' ? 'active' : ''}`}><Link to='/about-us' className={`py-3  nHover dropdown-item w-100 `} >About Us</Link></li>

                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/overview' ? 'active' : ''}`}><Link to='/overview' className={`py-3 nHover dropdown-item w-100 `} >RIT Overview</Link></li>
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/vision-mission' ? 'active' : ''}`}><Link to='/vision-mission' className={`py-3 nHover dropdown-item w-100 `} >Motto, Vission and Mission</Link></li>
                                           {/*  <li onClick={closeDropdown} className={` nHover ${location.pathname === '/kanchi_krishna_educational_trust' ? 'active' : ''}`}><Link style={{ width: '100%', paddingRight: '100px' }} to='/kanchi_krishna_educational_trust' className='py-3 dropdown-item nHover' >Kanchi Krishna Educational Trust</Link></li> */}
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/principal-message' ? 'active' : ''}`}><Link style={{ width: '100%', paddingRight: '100px' }} to='/principal-message' className={`py-3 dropdown-item nHover`} >Principal Message</Link></li>
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/chairman-message' ? 'active' : ''}`}><Link style={{ width: '100%', paddingRight: '100px' }} to='/chairman-message' className={`py-3 dropdown-item nHover`} >Chairman Message</Link></li>
                                           {/*  <li onClick={closeDropdown} className={`nHover ${location.pathname === '/rank-holders' ? 'active' : ''}`}><Link style={{ width: '100%', paddingRight: '100px' }} to='/rank-holders' className="py-3 dropdown-item nHover" >Rank Holders</Link></li> */}

                                        </ul>
                                    </div>

                                    {/* About us for mobile screen  */}
                                    <div id="accordion" className="d-block d-sm-none" style={{ background: '#002147' }}>
                                        <div className="card py-0  nav-item" style={{ background: '#002147', borderLeft: '0', borderRight: '0' }}>
                                            <div onClick={() => setPlus(!plus)} className="card-header d-flex align-items-center justify-content-between px-0 border-0" data-bs-toggle="collapse" href="#collapseOne">
                                                <a className="btn nav-link Mlist text-light px-0 "  >
                                                    About
                                                </a>
                                                {!plus ?
                                                    <i className="fa-solid fa-plus" style={{ color: 'white', fontSize: 'large' }}></i> :
                                                    <i className="fa-solid fa-minus" style={{ color: 'white', fontSize: 'large' }}></i>
                                                }
                                            </div>
                                            <div id="collapseOne" className="collapse hide" data-bs-parent="#accordion" >
                                                <div className="card-body text-light">
                                                    <ul style={{ listStyle: 'none' }} className='p-0'>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/about-us' className="dropdown-item" >About Us</Link></li>

                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/overview' className="dropdown-item" >RIT Overview</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/vision-mission' className="dropdown-item" >Motto, Vission and Mission</Link></li>
                                                        {/* <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/kanchi_krishna_educational_trust' className="dropdown-item" >Kanchi Krishna Educational Trust</Link></li> */}
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/principal-message' className="dropdown-item" >Principal Message</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/chairman-message' className="dropdown-item" >Chairman Message</Link></li>
                                                      {/*   <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/rank-holders' className="dropdown-item" >Rank Holders</Link></li> */}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* Acadamics for pc screen  */}
                                    <div className="dropdown d-none d-sm-block px-2" onMouseEnter={handleMouseEnterAcad} onMouseLeave={handleMouseLeaveAcad}>
                                        <li type="button" className="text-light Mlist" style={{ background: 'none', border: '0px' }}>
                                            <span className={`${isOpenAcad ? 'text-warning' : ''}`} style={{ borderTop: isOpenAcad ? '1px orange solid' : null }}>Department  <i className="fa-solid fa-angle-down" fontSize="small"></i></span>
                                        </li>

                                        <ul
                                            className={`rounded-4 py-0 dropdown-menu ${isOpenAcad ? 'show' : ''}`}
                                            ref={dropdownRef}
                                            style={{ marginLeft: '-10px', border: '0px', background: '#0098fe', color: 'white', minWidth: 'unset', marginTop: '1px' }}
                                        >
                                            <li style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}
                                                onMouseEnter={handleMouseEnterUnderGraduate}
                                                onMouseLeave={handleMouseLeaveUnderGraduate}
                                                className='py-3 nHover'
                                            >
                                                <li className=" nHover dropdown-item w-100" >
                                                    Diploma &nbsp;&nbsp;&nbsp;<i className="fa-solid fa-chevron-right"></i>
                                                </li>


                                                <ul className={`rounded-4 py-0 dropdown-menu ${isUnderGraduateOpen ? 'show' : ''}`} style={{ marginLeft: '110px', border: '0px', background: '#0098fe', color: 'white', minWidth: 'unset', marginTop: '-37px' }}>
                                                    <li className={` nHover ${location.pathname === '/electrical_electronics_engineering' ? 'active' : ''}`}><Link to='/electrical_electronics_engineering' className="py-3 nHover dropdown-item w-100" >Electrical and Electronics Engineering</Link></li>
                                                    <li className={` nHover  ${location.pathname === '/electronics-communication-engineering' ? 'active' : ''}`}><Link to='/electronics-communication-engineering' className="py-3 nHover dropdown-item w-100" >Electronics & Communication Engineering</Link></li>
                                                    <li className={` nHover ${location.pathname === '/mechanical_engineering' ? 'active' : ''}`}><Link to='/mechanical_engineering' className="py-3 nHover dropdown-item w-100" >Mechanical Engineering</Link></li>
                                                    <li className={` nHover ${location.pathname === '/computer_engineering' ? 'active' : ''}`}><Link to='/computer_engineering' className="py-3 nHover dropdown-item w-100" >Computer Engineering</Link></li>
                                                    <li className={` nHover ${location.pathname === '/automobile_engineering' ? 'active' : ''}`}><Link to='/automobile_engineering' className="py-3 nHover dropdown-item w-100" >Automobile Engineering</Link></li>
                                                    <li className={` nHover ${location.pathname === '/civil_engineering' ? 'active' : ''}`}><Link to='/civil_engineering' className="py-3 nHover dropdown-item w-100" >Civil Engineering</Link></li>



                                                </ul>

                                            </li>


                                        </ul>
                                    </div>


                                    {/* Acadamics  for mobile screen  */}

                                    <div id="accordion" className="d-block d-sm-none" style={{ background: '#002147' }}>
                                        <div className="card py-0  nav-item" style={{ background: '#002147', borderLeft: '0', borderRight: '0' }}>
                                            <div onClick={() => setPlusFaci(!plusFaci)} className="card-header d-flex align-items-center justify-content-between px-0 border-0" data-bs-toggle="collapse" href="#collapseFour">
                                                <a className="btn Mlist nav-link text-light px-0 "  >
                                                    Department
                                                </a>
                                                {!plusFaci ?
                                                    <i className="fa-solid fa-plus" style={{ color: 'white', fontSize: 'large' }}></i> :
                                                    <i className="fa-solid fa-minus" style={{ color: 'white', fontSize: 'large' }}></i>
                                                }
                                            </div>
                                            <div id="collapseFour" className="collapse hide" data-bs-parent="#accordion" >
                                                <div className="card-body text-light">
                                                    <ul style={{ listStyle: 'none' }} className='p-0'>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/electrical_electronics_engineering' className="dropdown-item" >Electrical and Electronics Engineering </Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/electronics-communication-engineering' className="dropdown-item" > Electronics & Communication Engineering</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/mechanical_engineering' className="dropdown-item" > Mechanical Engineering</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/computer_engineering' className="dropdown-item" > Computer Engineering</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/automobile_engineering' className="dropdown-item" >Automobile Engineering</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/civil_engineering' className="dropdown-item" >Civil Engineering</Link></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Co-curicular Activities  for pc screen  */}
                                    <div
                                        className="dropdown d-none d-sm-block px-2 "

                                        onMouseEnter={() => setIsOpenCo(true)}
                                        onMouseLeave={() => setIsOpenCo(false)} >
                                        <li
                                            type="button"
                                            className="text-light Mlist "
                                            style={{ background: 'none', border: '0px' }}
                                        >
                                            <span className={`${isOpenCo ? 'text-warning' : ''}`} style={{ borderTop: isOpenCo ? '1px orange solid' : null }}>Co-curicular Activities   <i className="fa-solid fa-angle-down" fontSize="small"></i> </span>
                                            <i classname="fa-solid fa-angle-down" style={{ fontSize: 'large' }}></i>
                                        </li>


                                        <ul
                                            className={`rounded-4 py-0 dropdown-menu ${isOpenCo ? 'show' : ''}`}
                                            ref={dropdownRef} style={{ MarginLeft: '-10px', border: '0px', background: '#0098fe', color: 'white', minWidth: 'unset', marginTop: '1px' }}
                                        >
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/nss' ? 'active' : ''}`} ><Link to='/nss' className={`py-3 nHover dropdown-item w-100 `} >National Service Scheme</Link></li>

                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/youth_red_cross' ? 'active' : ''}`}><Link to='/youth_red_cross' className="py-3 nHover dropdown-item w-100" >Youth Red Cross</Link></li>
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/yoga_club' ? 'active' : ''}`}><Link to='/yoga_club' className="py-3 nHover dropdown-item w-100" >Yoga Club</Link></li>
                                         
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/anti_ragging_cell' ? 'active' : ''}`}><Link style={{ width: '100%', paddingRight: '100px' }} to='/anti_ragging_cell' className="py-3 dropdown-item nHover" >Anti Ragging Cell</Link></li>
                                           

                                        </ul>
                                    </div>

                                    {/* Co-curicular Activities  for mobile screen  */}
                                    <div id="accordion" className="d-block d-sm-none" style={{ background: '#002147' }}>
                                        <div className="card py-0 nav-item" style={{ background: '#002147', borderLeft: '0', borderRight: '0' }}>
                                            <div
                                                onClick={() => setPlusCo(!plusCo)}
                                                className="card-header d-flex align-items-center justify-content-between px-0 border-0"
                                                data-bs-toggle="collapse"
                                                href="#collapseThree"
                                            >
                                                <a className="btn nav-link Mlist text-light px-0">Co-curricular Activities</a>
                                                {!plusCo ? (
                                                    <i className="fa-solid fa-plus" style={{ color: 'white', fontSize: 'large' }}></i>
                                                ) : (
                                                    <i className="fa-solid fa-minus" style={{ color: 'white', fontSize: 'large' }}></i>
                                                )}
                                            </div>
                                            <div id="collapseThree" className={`collapse ${plusCo ? 'show' : 'hide'}`} data-bs-parent="#accordion">
                                                <div className="card-body text-light">
                                                    <ul style={{ listStyle: 'none' }} className="p-0">
                                                        <li type="button" data-bs-dismiss="offcanvas" className="pb-1">
                                                            <Link to='/nss' className="dropdown-item">National Service Scheme</Link>
                                                        </li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className="pb-1">
                                                            <Link to='/youth_red_cross' className="dropdown-item">Youth Red Cross</Link>
                                                        </li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className="pb-1">
                                                            <Link to='/yoga_club' className="dropdown-item">Yoga Club</Link>
                                                        </li>
                                                       
                                                        <li type="button" data-bs-dismiss="offcanvas" className="pb-1">
                                                            <Link to='/anti_ragging_cell' className="dropdown-item">Anti Ragging Cell</Link>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    {/* Facilities us for pc screen  */}
                                    <div
                                        className="dropdown d-none d-sm-block px-2 "

                                        onMouseEnter={() => setIsOpenFaci(true)}
                                        onMouseLeave={() => setIsOpenFaci(false)} >
                                        <li
                                            type="button"
                                            className="text-light Mlist "
                                            style={{ background: 'none', border: '0px' }}
                                        >
                                            <span className={`${IsOpenFaci ? 'text-warning' : ''}`} style={{ borderTop: IsOpenFaci ? '1px orange solid' : null }}>Facilities <i className="fa-solid fa-angle-down" fontSize="small"></i> </span>
                                            <i classname="fa-solid fa-angle-down" style={{ fontSize: 'large' }}></i>
                                        </li>


                                        <ul
                                            className={`rounded-4 py-0 dropdown-menu ${IsOpenFaci ? 'show' : ''}`}
                                            ref={dropdownRef} style={{ MarginLeft: '-10px', border: '0px', background: '#0098fe', color: 'white', minWidth: 'unset', marginTop: '1px' }}
                                        >
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/infrastructure' ? 'active' : ''}`}><Link to='/infrastructure' className={`py-3 nHover dropdown-item w-100 `} >Infrastructure</Link></li>

                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/library' ? 'active' : ''}`}><Link to='/library' className="py-3 nHover dropdown-item w-100" >Library</Link></li>
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/transport' ? 'active' : ''}`}><Link style={{ width: '100%', paddingRight: '100px' }} to='/transport' className="py-3 dropdown-item nHover" >Transport</Link></li>
                                        </ul>
                                    </div>

                                    {/* Facilities us for mobile screen  */}
                                    <div id="accordion" className="d-block d-sm-none" style={{ background: '#002147' }}>
                                        <div className="card py-0  nav-item" style={{ background: '#002147', borderLeft: '0', borderRight: '0' }}>
                                            <div onClick={() => setPlusFaci(!plusFaci)} className="card-header d-flex align-items-center justify-content-between px-0 border-0" data-bs-toggle="collapse" href="#collapseFours">
                                                <a className="btn Mlist nav-link text-light px-0 "  >
                                                    Facilities
                                                </a>
                                                {!plusFaci ?
                                                    <i className="fa-solid fa-plus" style={{ color: 'white', fontSize: 'large' }}></i> :
                                                    <i className="fa-solid fa-minus" style={{ color: 'white', fontSize: 'large' }}></i>
                                                }
                                            </div>
                                            <div id="collapseFours" className="collapse hide" data-bs-parent="#accordion" >
                                                <div className="card-body text-light">
                                                    <ul style={{ listStyle: 'none' }} className='p-0'>

                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/infrastructure' className="dropdown-item" >Infrastructure</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/library' className="dropdown-item" >Library</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/transport' className="dropdown-item" >Transport</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                    {/* Activities us for pc screen  */}
                                    <div
                                        className="dropdown d-none d-sm-block px-2 "

                                        onMouseEnter={() => setIsOpenAct(true)}
                                        onMouseLeave={() => setIsOpenAct(false)} >
                                        <li
                                            type="button"
                                            className="text-light Mlist "
                                            style={{ background: 'none', border: '0px' }}
                                        >
                                            <span className={`${IsOpenAct ? 'text-warning' : ''}`} style={{ borderTop: IsOpenAct ? '1px orange solid' : null }}>Activities <i className="fa-solid fa-angle-down" fontSize="small"></i> </span>
                                            <i classname="fa-solid fa-angle-down" style={{ fontSize: 'large' }}></i>
                                        </li>


                                        <ul
                                            className={`rounded-4 py-0 dropdown-menu ${IsOpenAct ? 'show' : ''}`}
                                            ref={dropdownRef} style={{ MarginLeft: '-10px', border: '0px', background: '#0098fe', color: 'white', minWidth: 'unset', marginTop: '1px' }}
                                        >
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/placement' ? 'active' : ''}`}><Link to='/placement' className={`py-3 nHover dropdown-item w-100 `} >Placement and Training</Link></li>

                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/latest_news' ? 'active' : ''}`}><Link to='/latest_news' className="py-3 nHover dropdown-item w-100" >Latest News</Link></li>
                                            <li onClick={closeDropdown} className={` nHover ${location.pathname === '/gallery' ? 'active' : ''}`}><Link style={{ width: '100%', paddingRight: '100px' }} to='/gallery' className="py-3 dropdown-item nHover" >Gallery</Link></li>
                                        </ul>
                                    </div>

                                    {/* Activities us for mobile screen  */}
                                    <div id="accordion" className="d-block d-sm-none" style={{ background: '#002147' }}>
                                        <div className="card py-0  nav-item" style={{ background: '#002147', borderLeft: '0', borderRight: '0' }}>
                                            <div onClick={() => setPlusAct(!plusAct)} className="card-header d-flex align-items-center justify-content-between px-0 border-0" data-bs-toggle="collapse" href="#collapseFive">
                                                <a className="btn Mlist nav-link text-light px-0 "  >
                                                    Activities
                                                </a>
                                                {!plusAct ?
                                                    <i className="fa-solid fa-plus" style={{ color: 'white', fontSize: 'large' }}></i> :
                                                    <i className="fa-solid fa-minus" style={{ color: 'white', fontSize: 'large' }}></i>
                                                }
                                            </div>
                                            <div id="collapseFive" className="collapse hide" data-bs-parent="#accordion" >
                                                <div className="card-body text-light">
                                                    <ul style={{ listStyle: 'none' }} className='p-0'>

                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/placement' className="dropdown-item" >Placement and Training</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/latest_news' className="dropdown-item" >Latest News</Link></li>
                                                        <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link to='/gallery' className="dropdown-item" >Gallery</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                   {/*  <li className={`nav-item px-2 `} type="button" data-bs-dismiss="offcanvas">
                                        <Link className={`Mlist   ${location.pathname === '/admission' ? 'actives' : ''}`} to='/admission'> <span> Admission </span> </Link>
                                    </li> */}
                                    <li className={`nav-item px-2 `} type="button" data-bs-dismiss="offcanvas">
                                        <Link className={`Mlist   ${location.pathname === '/contact' ? 'actives' : ''}`} to='/contact'> <span> Contact Us </span> </Link>
                                    </li>
                                 {/*    <li className={`nav-item px-2 `} type="button" data-bs-dismiss="offcanvas">
                                        <Link className={`Mlist   ${location.pathname === '/aicte' ? 'actives' : ''}`} to='/aicte'> <span> AICTE Approval </span> </Link>
                                    </li> */}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar