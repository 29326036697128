import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/RIT COLLEGE LOGO.png'

const Civil = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Diploma in Civil Engineering" hide={true} />

            <div className='container-md'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ lineHeight: window.innerWidth < 800 ? '45px' : '' }}>Diploma in Civil Engineering</span> </h2>
                <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Introduction</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                Embark on a journey of structural innovation and urban development with our Diploma in Civil Engineering program. Grounded in a legacy of excellence, this program is designed to cultivate the next generation of civil engineering professionals.                 </p>



                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>History of the Department</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>

                The history of Diploma in Civil Engineering traces back to ancient civilizations, but the formalization of programs occurred during the industrial revolution. Evolving from rudimentary construction techniques, it adapted to include modern methodologies, environmental considerations, and technological advancements.
                 </p>

                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Vision and Mission</span> </h4>

                <div className="row m-0 mt-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h2>
                                    <p className='pt-2 text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Civil Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    To be a leader in Civil Engineering education, producing graduates who shape sustainable infrastructure and contribute to societal well-being.
                                     </p>
                                </div>
                            </div>
                        </div>
                        <div className="flip-container mt-5">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Civil Engineering</p>
                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    We are committed to delivering a comprehensive learning experience that blends theoretical knowledge, practical skills, and ethical values in the realm of civil engineering.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container mt-3" >
                            <div className="flip-box" style={{ height: '600px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Civil Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    <ul>
                                        <li>Continuously enhance the curriculum to reflect the latest advancements in civil engineering, ensuring graduates are well-equipped for the evolving industry.</li>
                                        <li> Strengthen ties with construction and infrastructure industries to provide students with hands-on experiences, internships, and exposure to real-world challenges.</li>
                                        <li>Foster a culture of professional development, encouraging students to pursue certifications, participate in conferences, and engage in lifelong learning to stay abreast of industry trends.</li>
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Department Library</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                The Department Library stands as a repository of civil engineering knowledge, offering a diverse collection of resources on structural design, geotechnical engineering, and construction management. It provides students with an immersive space for academic exploration and research.
                 </p>

                <h4 className='mt-2 py-2 text-start ' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Achievements</span> </h4>

                <div>
                    <div className='d-flex align-items-center justify-content-center'> <i style={{ color: 'white', fontSize: '50px' }} class="acHover rounded-circle p-3 fa-solid fa-trophy"></i>  </div>

                    <div className='mt-3 d-flex align-items-center justify-content-center '>
                        <p className='w-100 shadow rounded-3  mb-5 p-3 w-75' style={{ textAlign: 'center', color: '#393185', fontSize: '16px', fontWeight: '700', lineHeight: '32px' }}>

                        The Diploma in Civil Engineering program boasts a rich history of producing graduates who have contributed to landmark infrastructure projects globally. Alumni have excelled in areas such as bridge design, environmental engineering, and project management, earning recognition for their impactful contributions.
                         </p>
                    </div>
                </div>

            </div>

           
        </div>
    )
}

export default Civil