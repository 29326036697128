import React, { useLayoutEffect, useState } from 'react';
import FixedHeader from '../FixedHeader';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import C1 from '../../Assets/Infrastructure/Computer/C1.jpeg';
import C2 from '../../Assets/Infrastructure/Computer/C2.jpeg';
import C3 from '../../Assets/Infrastructure/Computer/C3.jpeg';
import C4 from '../../Assets/Infrastructure/Computer/C4.jpeg';
import C5 from '../../Assets/Infrastructure/Computer/C5.jpeg';
import C6 from '../../Assets/Infrastructure/Computer/C6.jpeg';
import EEE1 from '../../Assets/Infrastructure/EEE/EEE (1).jpeg';
import EEE2 from '../../Assets/Infrastructure/EEE/EEE (2).jpeg';
import EEE3 from '../../Assets/Infrastructure/EEE/EEE (3).jpeg';
import EEE4 from '../../Assets/Infrastructure/EEE/EEE (4).jpeg';
import EEE5 from '../../Assets/Infrastructure/EEE/EEE (5).jpeg';
import ECE1 from '../../Assets/Infrastructure/ECE/img1.jpg'
import ECE2 from '../../Assets/Infrastructure/ECE/img2.jpg'
import Civil1 from '../../Assets/Infrastructure/Civil/CIVIL.jpg';
import Civil2 from '../../Assets/Infrastructure/Civil/C2.jpg';
import Mec1 from '../../Assets/Infrastructure/Mechanical/5.jpg'
import Mec2 from '../../Assets/Infrastructure/Mechanical/8.jpg'

const Infrastructure = () => {
  const [index, setIndex] = useState(-1);

  const categories = [
    {
      name: 'Computer',
      photos: [
        { id: 1, src: C1, width: 1080, height: 900 },
        { id: 2, src: C2, width: 1080, height: 900 },
        { id: 3, src: C3, width: 1080, height: 900 },
        { id: 4, src: C4, width: 1080, height: 900 },
        { id: 5, src: C5, width: 1080, height: 900 },
        { id: 6, src: C6, width: 1080, height: 900 },
      ],
    },

    {
      name: 'EEE',
      photos: [
        { id: 1, src: EEE1, width: 1080, height: 900 },
        { id: 2, src: EEE2, width: 1080, height: 900 },
        { id: 3, src: EEE3, width: 1080, height: 900 },
        { id: 4, src: EEE4, width: 1080, height: 900 },
        { id: 5, src: EEE5, width: 1080, height: 900 },
      ],
    },
    {
      name: 'Mechanical',
      photos: [
        { id: 1, src: Mec1, width: 1080, height: 900 },
        { id: 2, src: Mec2, width: 1080, height: 900 },

      ],
    },
    {
      name: 'Civil', photos: [
        { id: 1, src: Civil1, width: 1080, height: 900 },
        { id: 2, src: Civil2, width: 1080, height: 900 }


      ]
    },
    {
      name: 'ECE', photos: [
        { id: 1, src: ECE1, width: 1080, height: 900 },
        { id: 2, src: ECE2, width: 1080, height: 900 },
      ]
    },
    {
      name: 'Automobile', photos: [
        { id: 1, src: Mec1, width: 1080, height: 900 },
        { id: 2, src: Mec2, width: 1080, height: 900 },


      ]
    }
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setIndex(-1); // Reset the lightbox index
  };

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);


  const selectedPhotos = selectedCategory ? selectedCategory.photos : [];
  const isCategoryEmpty = selectedPhotos.length === 0;
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <div>
      <FixedHeader title="Infrastructure" hide={true} />
      <div className='container-md'>
        <div className=' mb-5 '>
          <h2 className='py-2  text-center mt-2' style={{ color: '#0098fe', fontWeight: '700' }}>
            <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Infrastructure</span>
          </h2>
        </div>

        <div className="lg-react-element  my-5 ">
          {categories.map((category) => (
            <button
              key={category.name}
              className={`btn btn-primary m-2 ${selectedCategory.name === category.name ? 'selected' : ''}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category.name}
            </button>
          ))}

          {isCategoryEmpty && <p className='mt-4' style={{ fontWeight: '600' }}>There are no photos for this category.</p>}

          <div className={`d-flex align-items-center flex-wrap w-100 ${window.innerWidth < 600 ? 'justify-content-center' : ''}`}>
            {selectedPhotos.map((photo, photoIndex) => (
              <div key={photoIndex} style={{ width: window.innerWidth < 700 ? '150px' : '250px' }} className='p-2'>
                {photo.isVideo ? (
                  <video controls className='rounded-3 border w-100' >
                    <source src={photo.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <PhotoAlbum
                    photos={[photo]}
                    layout="rows"
                    targetRowHeight={150}
                    onClick={() => setIndex(photoIndex)}
                  />
                )}
              </div>
            ))}
          </div>

          {index >= 0 && !selectedPhotos[index].isVideo && (
            <Lightbox
              slides={selectedPhotos}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
              overlayStyles={{
                background: 'rgba(10, 10, 10, 1.3)',
              }}
            />
          )}

        </div>
      </div>
    </div>
  );
};

export default Infrastructure;
