import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/RIT COLLEGE LOGO.png'

const Automobile = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Diploma in Automobile Engineering" hide={true} />

            <div className='container-md'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ lineHeight: window.innerWidth < 800 ? '45px' : '' }}>Diploma in Automobile Engineering</span> </h2>
                <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Introduction</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                Step into the world of innovation and precision with our Diploma in Automobile Engineering program. Guided by a commitment to excellence, this program is designed to nurture future leaders in the dynamic field of automotive technology.                </p>



                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>History of the Department</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>

                The history of Diploma in Automobile Engineering dates back to the early 20th century, paralleling the growth of the automotive industry. Evolving from basic mechanical engineering programs, it adapted to the specialized needs of designing, manufacturing, and maintaining automobiles.   </p>

                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Vision and Mission</span> </h4>

                <div className="row m-0 mt-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h2>
                                    <p className='pt-2 text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Automobile Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    To be a trailblazer in Automobile Engineering education, shaping skilled professionals who drive innovation and sustainability in the automotive industry.                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flip-container mt-5">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Automobile Engineering</p>
                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    We are dedicated to providing a transformative learning experience, fostering technical expertise, ethical values, and a passion for advancing automotive engineering.                                     </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container mt-3" >
                            <div className="flip-box" style={{ height: '600px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Automobile Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    <ul>
                                        <li>Continuously enhance the curriculum to integrate the latest advancements in automobile technology, ensuring graduates are industry-ready.</li>
                                        <li>Foster collaborations with automotive industry leaders to provide students with real-world exposure through internships, workshops, and collaborative projects.</li>
                                        <li>Cultivate a culture of research and innovation within the department, encouraging faculty and students to contribute to advancements in automotive engineering.</li>
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Department Library</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                The Department Library is a hub of automotive knowledge, housing a diverse collection of resources on vehicle design, engine technology, and automotive systems. It serves as a center for academic exploration and research in the dynamic field of automobile engineering.                 </p>

                <h4 className='mt-2 py-2 text-start ' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Achievements</span> </h4>

                <div>
                    <div className='d-flex align-items-center justify-content-center'> <i style={{ color: 'white', fontSize: '50px' }} class="acHover rounded-circle p-3 fa-solid fa-trophy"></i>  </div>

                    <div className='mt-3 d-flex align-items-center justify-content-center '>
                        <p className='w-100 shadow rounded-3  mb-5 p-3 w-75' style={{ textAlign: 'center', color: '#393185', fontSize: '16px', fontWeight: '700', lineHeight: '32px' }}>

                        The Diploma in Automobile Engineering program has a legacy of producing skilled professionals who have made significant contributions to the automotive sector. Graduates have excelled in vehicle design, manufacturing, and maintenance, earning recognition for their innovation and technical prowess.
                         </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Automobile