import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/RIT COLLEGE LOGO.png'

const DEEE = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="Diploma in Electrical and Electronics Engineering" hide={true} />

            <div className='container-md'>
                    <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ lineHeight: window.innerWidth < 800 ?'45px': ''}}>Diploma in Electrical and Electronics Engineering</span> </h2>
                    <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Introduction</span> </h4>

                    <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        Our institution stands as a beacon of learning, fostering a dynamic and inclusive educational environment. With a commitment to excellence, we nurture the intellectual growth and holistic development of our students.                    </p>

                   

                    <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>History of the Department</span> </h4>

                    <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        The Department of Diploma in Electrical and Electronics Engineering was established in the year 1999 Department is equipped with well-qualified and dedicated faculty members to train students towards their holistic development.
                    </p>

                    <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Vision and Mission</span> </h4>

                    <div className="row m-0 mt-5">
                        <div className="col-lg-6 col-sm-12">
                            <div className="flip-container">
                                <div className="flip-box" style={{ height: '280px' }}>
                                    <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                        <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                        <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h2>
                                        <p className='pt-2 text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Electrical and Electronics Engineering</p>

                                    </div>
                                    <div className="back d-flex flex-column align-items-center justify-content-center" >
                                        <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h4>
                                        <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        To be a center of educational excellence, producing leaders who contribute meaningfully to society. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flip-container mt-5">
                                <div className="flip-box" style={{ height: '280px' }}>
                                    <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                        <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                        <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h2>
                                        <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Electrical and Electronics Engineering</p>
                                    </div>
                                    <div className="back d-flex flex-column align-items-center justify-content-center" >
                                        <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h4>
                                        <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        We strive to provide transformative education, empowering students with knowledge, skills, and values to thrive in a rapidly evolving world.                                       </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="flip-container mt-3" >
                                <div className="flip-box" style={{ height: '600px' }}>
                                    <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                        <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                        <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h2>
                                        <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Electrical and Electronics Engineering</p>

                                    </div>
                                    <div className="back d-flex flex-column align-items-center justify-content-center" >
                                        <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h4>
                                        <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        Our primary goal is to instill a passion for learning, critical thinking, and innovation in our students. Through rigorous academic programs and holistic development initiatives, we aim to shape well-rounded individuals ready to meet the challenges of the future.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Department Library</span> </h4>

                    <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    The department library serves as a hub of knowledge, offering a vast collection of resources tailored to the specific needs of Electrical and Electronics Engineering students. Equipped with the latest publications and research materials, it provides a conducive space for academic exploration and self-directed learning.                    </p>

                    <h4 className='mt-2 py-2 text-start ' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Achievements</span> </h4>

                    <div>
                   <div className='d-flex align-items-center justify-content-center'> <i style={{color:'white',fontSize:'50px'}} class="acHover rounded-circle p-3 fa-solid fa-trophy"></i>  </div>
                   
                     <div className='mt-3 d-flex align-items-center justify-content-center '>
                     <p className=' shadow rounded-3  mb-5 p-3 w-75' style={{ textAlign: 'center', color: '#393185', fontSize: '16px', fontWeight: '700', lineHeight: '32px' }}>

                     The Diploma in Electrical and Electronics Engineering program has achieved remarkable milestones. Our graduates have excelled in their careers, contributing to advancements in technology and industry.                         </p>
                     </div>
                    </div>

            </div>

           
        </div>
    )
}

export default DEEE