import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'

const Overview = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="RIT Overview" />
            <div className='container-sm'>
                <p className='mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '14px', fontWeight: '600', lineHeight: '32px' }}>
                    At RIT, our prime goal is to enable transformation. When a student enters RIT with many hopes and dreams, we make it our top priority and chief mandate to help them achieve transformation. Not just through the completion of academic milestones, but through a holistic development on the academic, professional and social front. With this vision of creating leaders for our society and our nation, we base our approach to education on the foundational pillars of discipline and ethical conduct. No true leader can evolve without understanding the power of discipline to achieve one’s goals, and the necessity of integrity in every aspect of life. We are a growing institution, and it is our commitment to invest every effort in this holistic, long term transformational process. To achieve this we combine the best ingredients for effective education: a robust academic structure, commitment and competent academicians, world-class infrastructure and an environment which can nurture young minds for the challenges of the future. Welcome to RIT! Come, be a part of the transformation!
                </p>

                <h3 className='py-2  text-start' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Overview </span> </h3>
                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '14px', fontWeight: '600', lineHeight: '32px' }}>
              <span style={{fontWeight:'700'}}>Ranipettai Institute of Technology College, walaja </span> is located in Tamilnadu. The education institution was established in the year 1995. The College was affiliated to Directorate of Technical Education, Chennai. It is approved by AICTE (All India Council for Technical Education).
                </p>
            </div>
            
        </div>
    )
}

export default Overview