import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/RIT COLLEGE LOGO.png'

const ME = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Diploma in Mechanical Engineering" hide={true} />

            <div className='container-md'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ lineHeight: window.innerWidth < 800 ? '45px' : '' }}>Diploma in Mechanical Engineering</span> </h2>
                <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Introduction</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    Welcome to the world of Mechanical Engineering at our esteemed institution. With a legacy of excellence, our program is designed to cultivate engineering prowess, critical thinking, and innovation in the ever-evolving landscape of mechanical systems and technology.
                </p>



                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>History of the Department</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>

                The roots of Diploma in Mechanical Engineering can be traced to the industrial revolution, with formal programs emerging in the early 20th century. Initially focused on machinery and manufacturing, the discipline has evolved to embrace cutting-edge technologies, contributing to advancements in diverse industries.                         </p>

                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Vision and Mission</span> </h4>

                <div className="row m-0 mt-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h2>
                                    <p className='pt-2 text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Mechanical Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    To be a global leader in Mechanical Engineering education, fostering innovation and producing adept professionals.   
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flip-container mt-5">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Mechanical Engineering</p>
                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    We are dedicated to providing a transformative learning experience, empowering students with technical expertise, ethical values, and a passion for solving real-world challenges.
                                     </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container mt-3" >
                            <div className="flip-box" style={{ height: '600px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Mechanical Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                    Our overarching goal is to inspire excellence in mechanical engineering education and research. We aim to nurture a culture of curiosity, creativity, and collaboration, preparing students to be industry leaders and innovators.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Department Library</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                The Department Library serves as a knowledge hub for Mechanical Engineering enthusiasts. Stocked with an extensive collection of resources, it provides students with a conducive space for study, research, and exploration of the latest developments in the field.
                 </p>

                <h4 className='mt-2 py-2 text-start ' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Achievements</span> </h4>

                <div>
                    <div className='d-flex align-items-center justify-content-center'> <i style={{ color: 'white', fontSize: '50px' }} class="acHover rounded-circle p-3 fa-solid fa-trophy"></i>  </div>

                    <div className='mt-3 d-flex align-items-center justify-content-center '>
                        <p className='w-100 shadow rounded-3  mb-5 p-3 w-75' style={{ textAlign: 'center', color: '#393185', fontSize: '16px', fontWeight: '700', lineHeight: '32px' }}>

                        The Diploma in Mechanical Engineering program boasts a legacy of accomplishments. Our graduates have excelled in diverse industries, showcasing their skills in design, manufacturing, and problem-solving. The program takes pride in producing professionals recognized for their contributions to technological advancements and innovation.
                         </p>
                    </div>
                </div>

            </div>

           
        </div>
    )
}

export default ME