import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'

const AntiRaggingCell = () => {

    const table = [
        { name: "KALVI KAVALAR B.BOSE", position: 'Chairman', category: 'Chairman', Occupation: "Chairman", Phone: '9443846666', Mobile: '04172-299502',  Address: "Walaja" },
        { name: "Dr.S.Saravanan M.E, PhD", position: 'Principal', category: 'Principal', Occupation: "Principal", Phone: '9443846666', Mobile: '04172-299502',  Address: "Walaja" },
     
    ]
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="Anti Ragging Cell" hide={true} />

            <div className='container-md my-5 '>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Anti Ragging Cell</span> </h2>

                <div className="table-responsive my-5">
                <table className="table table-bordered table-striped">
                    <thead  className="thead-dark" style={{ background: '#0098fe', color: 'white' }}>
                        <tr>
                            <th>Name</th>
                            <th>Position</th>
                            <th>Category</th>
                            <th>Present Designation/Occupation</th>
                            <th>Phone no</th>
                            <th>Mobile</th>
                            <th>Address</th></tr>
                    </thead>
                    <tbody style={{color:'#393185', fontWeight:'500'}}>
                        {table.map((tab, id) => {
                            return <tr key={id}>
                                <td>{tab.name}</td>
                                <td>{tab.position}</td>
                                <td>{tab.category}</td>
                                <td>{tab.Occupation}</td>
                                <td>{tab.Phone}</td>
                                <td>{tab.Mobile}</td>
                                <td>{tab.Address}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
}

export default AntiRaggingCell