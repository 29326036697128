import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import YRC from '../../Assets/YRC.png'

const Youth = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Youth Red Cross" hide={true} />

            <div className='container-md '>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Youth Red Cross</span> </h2>

                <div className="row m-0  mt-5">
                    <div className="col-lg-3 col-sm-12 d-flex align-items-start justify-content-center">
                        <img src={YRC} width='100%' alt="" />
                    </div>
                    <div className="col-lg-9 p-0 col-sm-12">
                        <p className=' mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                            The Youth Red Cross at Ranipettai Institute of Technology College embodies the spirit of humanitarian service, fostering a culture of compassion and empathy among our students.
                        </p>
                        <div className=' mt-3 mb-4'>
                            <p className=' mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                                Committed to promoting health and well-being, the Youth Red Cross organizes health camps, awareness drives, and first aid training sessions, ensuring our students are equipped to make a positive impact in times of need.                     </p>
                        </div>
                    </div>
                </div>

                <p className=' my-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>Through various community outreach programs, the Youth Red Cross engages with local communities, addressing health disparities, and contributing to the overall welfare of society.
                Our Youth Red Cross unit actively participates in disaster response preparedness, providing students with essential skills and knowledge to respond effectively to emergencies.
                <br /><br />
                Beyond service initiatives, the Youth Red Cross at Ranipettai Institute of Technology College  is dedicated to the holistic development of volunteers, emphasizing leadership, teamwork, and a strong sense of social responsibility.
                </p>


            </div>
        </div>
    )
}

export default Youth