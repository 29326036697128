import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/RIT COLLEGE LOGO.png'

const CE = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Diploma in Computer Engineering" hide={true} />

            <div className='container-md'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ lineHeight: window.innerWidth < 800 ? '45px' : '' }}>Diploma in Computer Engineering</span> </h2>
                <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Introduction</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    Welcome to the dynamic realm of Computer Engineering at our institution, where innovation meets technology. Our program is designed to equip students with the knowledge and skills needed to thrive in the rapidly evolving world of computing.
                </p>



                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>History of the Department</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>

                The history of Diploma in Computer Engineering traces back to the late 20th century when the surge in computing technology necessitated specialized education. The program evolved to encompass hardware, software, and network fundamentals, adapting continually to meet the dynamic demands of the digital age.                     </p>

                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Vision and Mission</span> </h4>

                <div className="row m-0 mt-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h2>
                                    <p className='pt-2 text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Computer Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        To be a pioneer in Computer Engineering education, producing forward-thinking professionals and industry leaders.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flip-container mt-5">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Computer Engineering</p>
                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        We are committed to providing a comprehensive learning environment that fosters creativity, critical thinking, and ethical responsibility in the field of computer engineering.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container mt-3" >
                            <div className="flip-box" style={{ height: '600px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Computer Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        <ul>
                                            <li> Continuously update and enhance the curriculum to stay abreast of the latest advancements in computer engineering, ensuring graduates are industry-ready.</li>
                                            <li> Establish strong collaborations with industry partners to facilitate internships, guest lectures, and collaborative projects, providing students with real-world exposure.</li>
                                            <li>Create a dynamic and inclusive learning environment that promotes student engagement, extracurricular activities, and leadership development to shape well-rounded computer engineering professionals.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Department Library</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    The Department Library serves as a digital haven for Computer Engineering enthusiasts. With a vast collection of resources spanning programming languages, algorithms, and emerging technologies, it offers students an immersive space for exploration and research.                 </p>

                <h4 className='mt-2 py-2 text-start ' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Achievements</span> </h4>

                <div>
                    <div className='d-flex align-items-center justify-content-center'> <i style={{ color: 'white', fontSize: '50px' }} class="acHover rounded-circle p-3 fa-solid fa-trophy"></i>  </div>

                    <div className='mt-3 d-flex align-items-center justify-content-center '>
                        <p className='w-100 shadow rounded-3  mb-5 p-3 w-75' style={{ textAlign: 'center', color: '#393185', fontSize: '16px', fontWeight: '700', lineHeight: '32px' }}>

                            The Diploma in Computer Engineering program has a rich legacy of achievements. Our graduates have excelled in software development, system design, and technological innovation. The program prides itself on producing professionals recognized for their contributions to the ever-evolving world of computing.                         </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default CE