import React from 'react'
import Logo from '../Assets/RIT COLLEGE LOGO.png'

const FixedHeader = ({ title , hide, title2}) => {
    return (
        <>
            <div className='BgImgHeader  d-flex align-items-center '>
                
                <h1 className='text-light px-4' style={{ fontWeight: '900px', fontWeight:'700' }}>{title}</h1>
            </div>

    {!hide && 
            <div className='d-flex my-3 align-items-center flex-column' >
                <img src={Logo} className='rounded-circle' width='110px' alt="" />
                {title2 ? 
                                <h2 className='text-center py-2' style={{ borderBottom: '4px solid #FF9800', color: '#0098fe', fontWeight: '700' }}>{title2}</h2>:
                                <h1 className='text-center py-2' style={{ borderBottom: '4px solid #FF9800', color: '#0098fe', fontWeight: '700' }}>RANIPPETTAI INSTITUTE OF TECHNOLOGY COLLEGE</h1>

            }
            </div> }
        </>

    )
}

export default FixedHeader