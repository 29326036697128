import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/RIT COLLEGE LOGO.png'

const VissionMission = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="Vision, Mission, & Motto" />
            <div className='container-sm my-5'>
                <div className="row m-0">
                    <div className="col-lg-2 shadow  col-sm-12" style={{ background: '#F7F7F7', borderRight: '5px solid #FF9800', color: '#0098fe', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}>
                        <h2 className="py-4 m-0 px-4" style={{ fontWeight: '700' }}>Motto </h2>
                    </div>
                    <div className="col-lg-10 col-sm-12" style={{ background: '#0098fe', color: 'white', borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }}>
                        <p className='py-2 m-0' style={{ lineHeight: '32px', fontSize: '14px', color: 'white', fontWeight: '700' }}>Ranipettai Institute of Technology College . This trust was established with one key objective: helping rural students realize their dreams through education of international standards.</p>
                    </div>

                    <div className="row m-0 mt-5">
                        <div className="col-lg-6 my-2 col-sm-12">
                            <div className="flip-container">
                                <div className="flip-box">
                                    <div className="front d-flex flex-column align-items-center justify-content-center">
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                        <h2 style={{fontWeight:'700', color:'#393185'}}>Vision</h2>
                                    </div>
                                    <div className="back d-flex flex-column align-items-center justify-content-center" >
                                        <h4 style={{fontWeight:'700', color:'#FF9800'}}>Vision</h4>
                                        <p style={{fontWeight:'700', fontSize:"13px", color:'white', lineHeight:'28px', textAlign:'justify'}} className='px-4'>
                                        Empowering minds, shaping futures – Our college envisions becoming a beacon of transformative education, fostering innovation, and nurturing global leaders poised to make a positive impact on society.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 my-2  col-sm-12">
                            <div className="flip-container">
                                <div className="flip-box">
                                    <div className="front d-flex flex-column align-items-center justify-content-center">
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                        <h2 style={{fontWeight:'700', color:'#393185'}}>Mission</h2>
                                    </div>
                                    <div className="back d-flex flex-column align-items-center justify-content-center" >
                                        <h4 style={{fontWeight:'700', color:'#FF9800'}}>MISSION</h4>
                                        <p style={{fontWeight:'700', fontSize:"13px", color:'white', lineHeight:'28px', textAlign:'justify'}} className='px-4'>
                                        Dedicated to excellence in education, our mission is to provide a dynamic learning environment that encourages intellectual growth, instills ethical values, and prepares students to thrive in a rapidly evolving world. We are committed to creating a community that fosters curiosity, critical thinking, and a passion for lifelong learning.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default VissionMission