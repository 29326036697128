import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Principle from '../../Assets/Principal Photo.jpeg'
const PrinchipleMess = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="Principal Message" hide={true} />
            <h2 className='py-2 px-5 text-center' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2 px-3' style={{ borderBottom: '5px solid #FF9800' }}>Principal Message</span> </h2>
            <div className="container-md">
                <div className="row m-0 my-5">
                    <div className="col-lg-4 col-sm-12 d-flex align-items-start justify-content-center">
                        <img className='rounded-3 shadow ' width='100%' src={Principle} alt="" />
                    </div>
                    <div className="col-lg-8 mt-4 col-sm-12">
                        <p style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        It is with great pleasure that I welcome you to our Ranippettai Institute of Technology website.
                        </p>

                        <p style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        As Principal I am hugely impressed by the commitment of the management and the staff for providing excellent all-round education for our students. As a team working together, we strongly promote academic achievement among our students. The cultural, sporting and other successes of all of our students and staff  are also proudly celebrated together
                        </p>

                        <p style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        Ranippettai Institute of Technology as an innovative college makes use of the talents and skills of staff, students and parents to provide a wide range of educational programmes and projects. Wholesome participation is encouraged in extra-curricular activities and care is also taken to ensure the well-being and happiness of each and every student in the college.
                        </p>

                        <p style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        With a long and rewarding history of achievement in education behind us, our college community continues to move forward  together with confidence, pride and enthusiasm
                        </p>
                        <div className='text-end'>
                            <p className='m-0' style={{ color: '#393185', fontSize: '14px', fontWeight: '700' }}>Dr.S.Saravanan M.E, PhD </p>
                            <p className='m-0 pt-1' style={{ color: '#393185', fontSize: '14px', fontWeight: '600' }}> Principal, </p>
                            <p className='m-0 pt-1' style={{ color: '#393185', fontSize: '14px', fontWeight: '600' }}> Ranippettai Institute of Technology </p>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PrinchipleMess