import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import NssImg from '../../Assets/NSS.png'

const NSS = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="National Service Scheme" hide={true} />

            <div className='container-md '>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>National Service Scheme</span> </h2>

                <div className="row m-0   mt-5">
                    <div className="col-lg-3 col-sm-12 d-flex align-items-center justify-content-center">
                        <img width='100%' src={NssImg} alt="" />
                    </div>
                    <div className="col-lg-9 p-0 col-sm-12">
                        <p className='mt-3 mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                            The National Service Scheme (NSS) at RANIPPETTAI INSTITUTE OF TECHNOLOGY COLLEGE is a vibrant and integral part of our institution, dedicated to fostering social responsibility and community engagement among our students.
                        </p>
                        <p className='mt-3 mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                            With a mission to instill the values of service, leadership, and social awareness, NSS provides a platform for students to actively contribute to community development and nation-building.                        </p>
                    </div>
                </div>

                <div className='p-0  mt-3 mb-5'>
                    <p className=' mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        NSS empowers our students to be proactive contributors to society. Through various programs and initiatives, volunteers develop a sense of civic responsibility and empathy for the less privileged.
                       
                        The NSS unit at RANIPPETTAI INSTITUTE OF TECHNOLOGY COLLEGE  actively engages with local communities, organizing outreach programs, health camps, and awareness drives that address the pressing needs of the community.
                        <br />
                        <br />
                        Apart from fostering a spirit of service, NSS focuses on the holistic development of students. Workshops, training sessions, and skill development programs are integral to our NSS initiatives.
                      
                        NSS at RANIPPETTAI INSTITUTE OF TECHNOLOGY COLLEGE spearheads campaigns on various social issues such as health and hygiene, literacy, gender equality, and civic responsibilities, fostering awareness and positive change.
                        <br />
                        <br />
                        Our NSS unit consistently receives recognition and awards for its outstanding contributions to community service and social welfare, showcasing the commitment of our students to the NSS ideals

                    </p>
                </div>

            </div>
        </div>
    )
}

export default NSS