import React, { useLayoutEffect, useRef, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import Logo from '../../Assets/RIT COLLEGE LOGO.png'
import { Link } from 'react-router-dom';
import img1 from '../../Assets/Infrastructure/Computer/C1.jpeg'
import img2 from '../../Assets/Infrastructure/Computer/C2.jpeg'
import img3 from '../../Assets/Infrastructure/Computer/C3.jpeg'
import img4 from '../../Assets/Infrastructure/Computer/C4.jpeg'
import imglab1 from '../../Assets/Infrastructure/EEE/EEE (1).jpeg'
import imglab2 from '../../Assets/Infrastructure/EEE/EEE (2).jpeg'
import imglab3 from '../../Assets/Infrastructure/EEE/EEE (3).jpeg'
import imglab4 from '../../Assets/Infrastructure/EEE/EEE (4).jpeg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Thubminail from '../../Assets/News&Events/Republic/Thumbnail.jpg'
import SportThumb from '../../Assets/News&Events/SportsDay/10.jpg'
import VoterThumb from '../../Assets/News&Events/VotersDay/Thumb.jpg'

const Home = () => {
  const [counterOn, setCounteron] = useState(false)

  const marqueeRef = useRef(null);

  const handleMouseEnter = () => {
    marqueeRef.current.stop();
  };

  const handleMouseLeave = () => {
    marqueeRef.current.start();
  };

  const photos = [
    { src: 'https://ranipettaiengcollege.com/images/3.jpg' },
    { src: 'https://ranipettaiengcollege.com/images/2.jpg' },
    { src: img1 },
    { src: img2 },
    { src: img3 },
    { src: img4 },

  ]

  const photos2 = [
    { src: imglab1 },
    { src: imglab2 },
    { src: imglab3 },
    { src: imglab4 },

  ]

  const data = [
    { img: Thubminail, title: "Republic Day", date: "Jan 26, 2024", link:'/latest_news' },
    { img: SportThumb, title: "Sports Day", date: "Jan 22, 2024", link:'/latest_news' },
    { img: VoterThumb, title: "Voters Day", date: "Jan 25, 2024", link:'/latest_news' },

  ]


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,    // Display one slide at a time
    slidesToScroll: 1,  // Scroll one slide at a time
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  return (
    <div>

      <Carousel>
        {photos.map((photo, index) => (
          <Carousel.Item key={index} id='Carousel'>
            <div style={{ height: window.innerWidth < 550 ? '300px' : window.innerWidth < 1100 ? '500px' : '70vh' }}>
              <img
                className="d-block w-100 img-fluid"
                src={photo.src}
                alt={`Slide ${index}`}
                style={{ height: '100%', objectFit: 'cover' }}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <div className=' mt-2 mb-5 d-flex align-items-center'>
        <div className='py-2' style={{ paddingLeft: '25px', paddingRight: '8px', background: '#0098fe', color: 'white', fontWeight: '600px' }}>Announcement</div>
        <marquee ref={marqueeRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ color: 'red', borderTop: '4px solid #393185', borderBottom: '4px solid #393185', fontWeight: '800px' }} className="marque py-1" scrollamount="15px">Welcome To Ranipettai Institute of Technology College | Admission Open 2024-25 For Diploma EEE, ECE, Mechanical, Computer, Automobile & Civil Engineering |  </marquee>
        <div className='px-3  d-none d-md-block' style={{ borderTopLeftRadius: '50%', background: '#0098fe', color: 'white', fontWeight: '600px' }}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>

      </div>

      <div className='container-sm'>
        <div className='d-flex align-items-center flex-column'>
          <img src={Logo} alt="" width='130px' />
          <h1 className='text-center p-2' style={{ borderBottom: '4px solid #FF9800', color: '#0098fe', fontWeight: '700' }}>RANIPPETTAI INSTITUTE OF TECHNOLOGY COLLEGE</h1>
        </div>


        <div className='mt-3 row' style={{ minHeight : window.innerWidth < 774 ? '' : '100px' }}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h4 className='w-100' style={{ fontWeight: '700', borderBottom: '3px solid #0098fe', color: '#0098fe' }}>Welcome to RIT</h4>
            <p style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Open Sans", sans-serif', textAlign: 'justify', lineHeight: '35px', color: '#393185' }}>Welcome to Ranipettai Institute of Technology College - where academic excellence meets vibrant campus life! At the heart of innovation and learning, our institution fosters a community of driven individuals. Join us on a journey of discovery, collaboration, and personal growth. Explore endless possibilities at Ranipettai Institute of Technology College. Welcome to your future!
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 my-3">
            <Carousel>
              {photos2.map((photo, index) => (
                <Carousel.Item key={index} id='Carousel'>
                  <div>
                    <img
                      className="d-block w-100 img-fluid rounded-2"
                      src={photo.src}
                      alt={`Slide ${index}`}
                      style={{ height: '350px', objectFit: 'cover' }}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      <div className='BgHomeImg my-2 d-flex align-items-center justify-content-center'>
        <ScrollTrigger className='w-100' onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>

          {counterOn && <div className="row mx-0 d-flex align-items-center justify-content-around w-100">
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
              <div className='border shadow rounded-3 m-3 p-3  d-flex flex-column justify-content-center align-items-center' style={{ width: '80%', minHeight: '200px' }}>
                <h1 className=' text-center' style={{ color: 'rgb(255,152,0)', fontSize: '68px', fontWeight: '700' }}><CountUp start={0} end={3} duration={2} delay={0} /></h1>
                <h3 className='text-center text-light'>Years in Education</h3>
              </div>

            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
              <div className='border shadow rounded-3 m-3 p-3  d-flex flex-column justify-content-center align-items-center' style={{ width: '80%', minHeight: '200px' }}>
                <h1 className=' text-center' style={{ color: 'rgb(255,152,0)', fontSize: '68px', fontWeight: '700' }}><CountUp start={0} end={6} duration={2} delay={0} /></h1>
                <h3 className='text-center text-light'>Diploma Program</h3>
              </div>

            </div>
            <div className="col-lg-4 col-md-6   col-sm-12 d-flex flex-column justify-content-between align-items-center">

              <div className='border shadow rounded-3 m-3 p-3 d-flex flex-column justify-content-center align-items-center' style={{ width: '80%', minHeight: '200px' }}>
                <h1 className='  text-center' style={{ color: 'rgb(255,152,0)', fontSize: '68px', fontWeight: '700' }}><CountUp start={0} end={200} duration={2} delay={0} />+</h1>
                <h3 className='text-center text-light'>Rank Holders</h3>
              </div>
            </div>
          </div>}
        </ScrollTrigger>
      </div>

      <div className="container-sm">
      <h4 className='w-100 mt-3' style={{ fontWeight: '700', borderBottom: '3px solid #0098fe', color: '#0098fe' }}>News and Events</h4>


        <Slider {...settings}>
          {data.map((d, id) => {

            return (
              <div key={id} className={`bg-light text-dark my-2 rounded-4 shadow`}>
               <Link to={d.link}> <div style={{ height: '250px' }} className='rounded-3 bg-primary d-flex align-items-center justify-content-center'>
                  <img src={d.img} alt="" style={{ objectFit: 'cover', width: '100%', height: '100%' }} className='shadow-sm rounded-3 img-fluid' />
                </div>

                <div className='d-flex flex-column align-items-center justify-content-center p-2' style={{ gap: '3px' }}>
                  <h5>{d.title}</h5>
                  <p className='text-secondary'>{d.date}</p>
                </div>
                </Link>
              </div>
            );
          })}
        </Slider>




        {/*         <div className='my-5'>
          <h4 className='w-100' style={{ fontWeight: '700', borderBottom: '3px solid #393185', color: '#0098fe' }}>UNIVERSITY RANK HOLDERS</h4>

          <div className='d-flex align-items-center justify-content-center flex-wrap'>
            {
              Holders.map((hold, id) => (
                <img width={window.innerWidth < 600 ? '120px' : '180px'} className='m-3 zoom-effect' style={{ borderRadius: '10px', transition: 'transform 0.2s ease-in-out', border: '4px solid #0098fe' }} key={id} src={hold.img} alt="" />
              ))
            }
          </div>
          <div className='w-100 d-flex align-items-center justify-content-center'>
            <Link to='/rank-holders' className='shadow-lg btn rounded-5 px-5 text-light ReadBtn' style={{ fontWeight: '700' }}>  Read More...</Link>
          </div>
        </div> */}
      </div>


      {/*       <div className='BGimg shadow my-5'>
        <div className="row m-0 p-0">
          <div className="zoom-effect col-lg-4 col-sm-4 d-flex align-items-center justify-content-center h-100">
            <h3 className='d-flex align-items-center justify-content-center pt-5' style={{ fontWeight: '700', color: '#393185', height: '100%' }}>COURSES</h3>
          </div>
          <div className="zoom-effect col-lg-4 col-sm-4 d-flex align-items-center justify-content-center h-100">
            <h3 className='d-flex align-items-center justify-content-center pt-5' style={{ fontWeight: '700', color: '#393185', height: '100%' }}>EVENTS</h3>
          </div>
          <div className="zoom-effect col-lg-4 col-sm-4 d-flex align-items-center justify-content-center h-100">
            <h3 className='d-flex align-items-center justify-content-center pt-5' style={{ fontWeight: '700', color: '#393185', height: '100%' }}>LATEST NEWS</h3>
          </div>
        </div>
      </div> */}


    </div>
  )
}

export default Home