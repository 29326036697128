import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Lib from '../../Assets/Library.jpg'

const Library = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Library" hide={true} />

            <div className='container-md mb-5'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Library</span> </h2>

                <div className="row m-0  mt-5">
                <div className="col-lg-5 mb-3 col-sm-12 d-flex align-items-center justify-content-center">
                        <img width='100%' className='rounded-3' style={{border:'4px solid #0098fe'}} src={Lib} alt="" />
                    </div>
                    <div className="col-lg-7 p-0 col-sm-12">
                        <p className='mt-3 mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '500', lineHeight: '32px' }}>
                            The college library serves as a dynamic hub for academic resources and intellectual engagement, providing students and faculty with an enriching learning environment. Here are ten lines highlighting the key features of our college library:                       
                            Our library boasts a vast collection of books, journals, and digital resources, covering a wide range of subjects to support the academic curriculum.
                    
                            
                             </p>
                    </div>
                </div>
                <p  className='mt-3 mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '500', lineHeight: '32px' }}>
                Our dedicated team of experienced librarians provides guidance and assistance to students, helping them navigate the extensive collection and locate relevant information.
                The library integrates e-learning platforms, facilitating remote access to educational materials and promoting a blended learning environment.
                The library serves as a focal point for intellectual discourse and community engagement, hosting events, book clubs, and discussions that enrich the academic experience.
                </p>
            </div>

        </div>
    )
}

export default Library