import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import YogaImg from '../../Assets/yoga.png'

const Yoga = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="Yoga Club" hide={true} />

            <div className='container-md my-5 '>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Yoga Club</span> </h2>

                <div className="row m-0  mt-5">
                    <div className="col-lg-3 col-sm-12 d-flex align-items-start justify-content-center">
                        <img src={YogaImg} width='100%' alt="" />
                    </div>
                    <div className="col-lg-9 p-0 col-sm-12">
                        <p className=' mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        The Yoga Club at RANIPPETTAI INSTITUTE OF TECHNOLOGY COLLEGE is a haven for students seeking holistic well-being, offering a space for physical, mental, and spiritual rejuvenation through the practice of yoga.
                        Committed to promoting mind-body harmony, the Yoga Club organizes regular yoga sessions, workshops, and meditation practices, providing students with tools for stress relief, focus, and inner balance.
                        </p>
                        <p className=' mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                        The Yoga Club welcomes students of all levels, from beginners to advanced practitioners, fostering an inclusive community where individuals can explore and deepen their yoga journey.
                        With a focus on advocating a healthy lifestyle, the Yoga Club encourages students to integrate yoga principles into their daily lives, promoting physical fitness, mental resilience, and a sense of tranquility.
                                                </p>
                    
                    </div>
                </div>

                

            </div>
        </div>
    )
}

export default Yoga