import React from 'react'
import Logo from '../Assets/RIT COLLEGE LOGO.png'
import { Link } from 'react-router-dom'

const Footer = () => {

    const recEvents = [
        { date: 26, days: '01 2024', title: "Republic Day Celebration", link:'/gallery' },
        { date: 22, days: '01 2024', title: "Sports Day", link:'/gallery' },
        { date: 25, days: '01 2024', title: "Voters Day", link:'/gallery' },


    ]
    return (

        <>
            <div style={{ background: '#067bc9' }} className='mt-5 foot'>
                <div className='container-fluid py-3'>
                    <div className="row m-0">

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <img width='100px' height='100px' className='rounded-3' src={Logo} alt="" />
                   <p className='p-0 m-0 mt-3' style={{ fontSize: '16px', color: 'white', lineHeight: '1.3rem', fontFamily: 'roboto', lineHeight:'28px', textAlign:'justify' }}>Ranipettai Institute of Technology Walaja, is located in Tamilnadu. The education institution was established in the year 1995. The College was affiliated to Directorate of Technical Education, Chennai.</p>
                    </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h3 className='py-2' style={{ borderBottom: '3px solid #FF9800', color: 'white', fontWeight: '700', fontFamily: 'roboto' }}>Ranipettai Institute of Technology College</h3>
                            <div className="row pt-3">
                                <div className="col-1 custom-col-2 "><i style={{ color: '#FF9800' }} className="fa-solid fa-location-dot"></i> </div>
                                <div className="col-10 p-0 "><p className='p-0 m-0' style={{ fontSize: '16px', color: 'white', lineHeight: '1.3rem', fontFamily: 'roboto' }}>
                                Thenkadappanthangal, Walaja(TK), Ranipettai - 632513
                                </p></div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-1 custom-col-2  "><i style={{ color: '#FF9800' }} className="fa-solid fa-mobile-screen-button"></i></div>
                                <div className="col-10 p-0 "><p className='p-0 m-0' style={{ fontSize: '16px', color: 'white', lineHeight: '1.3rem', fontFamily: 'roboto' }}>
                                    04172-299502, 9443846666
                                </p></div>
                            </div>
                            <hr />

                            <div className="row">
                                <div className="col-1 custom-col-2  "><i style={{ color: '#FF9800', paddingRight: '0px !important' }} className="fa-solid fa-envelope"></i></div>
                                <div className="col-10 p-0 "><p className='p-0 m-0' style={{ fontSize: '16px', color: 'white', lineHeight: '1.3rem', fontFamily: 'roboto' }}>
                                    ritthen30959@yahoo.co.in,
                                    388ritexamcell@gmail.com,
                                    ritthen30959@gmail.com,

                                </p></div>
                            </div>
                            <hr />


                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h3 className='py-2' style={{ borderBottom: '3px solid #FF9800', color: 'white', fontWeight: '700', fontFamily: 'roboto' }}>Recent Events <br /><br />  </h3>

                            <div className='mt-3'>
                                {
                                    recEvents.map((m, id) => (
                                        <li key={id} className='p-3 mb-3 rounded-4 border' style={{ listStyle: 'none', fontFamily: 'roboto' }}>
                                           <Link to={m.link}><div className='d-flex'>
                                                <div className='d-flex flex-column align-items-center justify-content-center rounded-3 text-light' style={{ width: '80px', height: '80px', background: '#FF9800',textDecoration:'none' }}>
                                                    <h4  className='m-0' style={{ fontWeight: '700',textDecoration:'none' }}>{m.date} </h4>
                                                    <p className='m-0' style={{textDecoration:'none'}}>{m.days}</p>
                                                </div>
                                                <h6 className='px-4 pt-2 hoverLink' style={{ fontWeight: '700', color: 'white' ,textDecoration:'none'}}>{m.title}</h6>
                                            </div> </Link> 
                                        </li>
                                    ))
                                }
                            </div>
                        </div>

                       
                    </div>
                </div>
            </div>

            <div className='py-2 shadow' style={{ borderTop: '2px solid #FF9800', background: '#0098fe' }} >
                <div className='container-md'>
                    <div className="row d-flex align-items-center justify-content-between px-2">
                        <div className="col-lg-8 col-sm-12" style={{ textAlign: window.innerWidth < 764 ? 'center' : 'unset' }}>
                            <p className='m-0' style={{ fontWeight: '600', fontSize: '13px' }}>© Copyright 2024 | Ranipettai Institute of Technology College | All Rights Reserved</p>
                        </div>
                        <div className="col-lg-4 py-2 col-sm-12 m-0 align-end" style={{ textAlign: window.innerWidth < 764 ? 'center' : 'unset' }}>
                            <a className="btn text-light btn-floating " href="#!" role="button"
                            ><i className="iSize fab fa-facebook-f"></i></a>

                            <a className="btn text-light btn-floating " href="#!" role="button"
                            ><i className="iSize fab fa-youtube"></i></a>

                            <a className="btn text-light btn-floating " href="#!" role="button"
                            ><i className="iSize fab fa-twitter"></i></a>

                            <a className="btn text-light btn-floating" href="#!" role="button"
                            ><i className="iSize fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Footer