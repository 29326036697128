import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Thubminail from '../../Assets/News&Events/Republic/Thumbnail.jpg'
import { Link } from 'react-router-dom'
import SportThumb from '../../Assets/News&Events/SportsDay/10.jpg'
import VoterThumb from '../../Assets/News&Events/VotersDay/Thumb.jpg'

const News = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })
  const News = [
    { img: Thubminail, title: "Republic Day",  date: "Jan 26, 2024", link : '/gallery' },
    { img: SportThumb, title: "Sports Day",  date: "Jan 22, 2024", link : '/gallery' },
    { img: VoterThumb, title: "Voters Day",  date: "Jan 25, 2024", link : '/gallery' }
  ]
  return (
    <div>
      <FixedHeader title="Latest News" hide={true} />

      <div className='container-md '>
        <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Latest News</span> </h2>


        <div className='d-flex mt-5 flex-wrap align-items-center justify-content-start'>
          {
            News.map((Event, id) => {
              return <li key={id} className='border mx-2 shadow rounded-3 p-3 d-flex  justify-content-start'>
                <Link to={Event.link}><img className='rounded-3' style={{objectFit:'cover'}} width='250px' height='150px' src={Event.img} alt="" />
                <div className='d-flex flex-column align-items-start  justify-content-start'>
                  <h5 style={{ fontWeight: '700', color:'#624B87' }} className='p-0 pt-2  m-0'>{Event.title}</h5>
                  <p style={{ fontWeight: '600', color: '#393185', fontSize: "14px" }} className='p-0 text-secondary pt-2 m-0' >{Event.date}</p>
                </div>
                </Link>
              </li>
            })
          }
        </div>
      </div>

    </div>
  )
}

export default News