import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/placement.jpg'

const Placement = () => {
  useLayoutEffect(()=>{
    window.scrollTo(0,0)
  })
/*     const placed = [
        {
          year: 2021,
          data: [
            { name: "Shivani S", course: "BE ECE", company: 'Draup' },
            { name: "Shivani S", course: "BE ECE", company: 'Draup' },
            { name: "Shivani S", course: "BE ECE", company: 'Draup' },
            { name: "Shivani S", course: "BE ECE", company: 'Draup' },
          ]
        }
      ]; */
    return (
        <div>
            <FixedHeader title="Placement and Training" hide={true} />

            <div className='container-md mb-5'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2'>Placement and Training</span> </h2>
                <p className='text-center'><span className='pb-2 ' style={{lineHeight:'32px', color: '#393185', fontWeight: '700', borderBottom: '4px solid #FF9800' }}>“We create the Opportunity and Make the Students Placed ”</span>  </p>

                <div className="row m-0 mt-5">
                    <div className="col-lg-5 col-sm-12 d-flex align-items-center justify-content-center">
                    <img width='100%' className='rounded-3' style={{border:'4px solid #0098fe'}} src={Logo} alt="" />
                    </div>
                    <div className="col-lg-7 p-0 col-sm-12">
                        <p className=' mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '500', lineHeight: '32px' }}>

                        Our college is dedicated to fostering career development and ensuring a bright future for our students. Our robust placement cell actively collaborates with leading industries to provide excellent placement opportunities. With a focus on skill development and industry-relevant training, we empower students to meet the demands of the ever-evolving job market. Our consistent track record of successful placements reflects our commitment to shaping well-rounded and employable graduates.                        </p>
                    </div>
                </div>

                <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Placement Scenario</span> </h4>

                <ul className=' mb-3 pt-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    <li>The Recruitment of fresh engineering graduates by all leading manufacturing sectors, IT service sectors has drastically changed and started focusing on “Industry 4.0”.</li>
                    <li>The campus hiring scenario and the process of selection is completely shifted towards industrial expectations in terms of skill sets.</li>
                    <li>There is a big gap between the industrial expectation and the present syllabus of all engineering disciplines.</li>
                    <li>All industries coming under manufacturing sector or software services sector, expect all fresh graduate engineers getting hired through campus interview should be available to them in a form of “Billable Resource” from the day one they join the organization without any investment of time and cost through training imparted to them.</li>
                    <li>Expectation of the recruiters and their new policy of recruitment in hiring fresher is purely based on the criteria of the student’s skill set capacity in terms of “Deployment” instead of “Employment”</li>
                </ul>

                <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Training Plan</span> </h4>

                <ul className=' mb-3 pt-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    <li>The Recruitment of fresh engineering graduates by all leading manufacturing sectors, IT service sectors has drastically changed and started focusing on “Industry 4.0”.</li>
                    <li>The campus hiring scenario and the process of selection is completely shifted towards industrial expectations in terms of skill sets.</li>
                    <li>There is a big gap between the industrial expectation and the present syllabus of all engineering disciplines.</li>
                    <li>All industries coming under manufacturing sector or software services sector, expect all fresh graduate engineers getting hired through campus interview should be available to them in a form of “Billable Resource” from the day one they join the organization without any investment of time and cost through training imparted to them.</li>
                    <li>Expectation of the recruiters and their new policy of recruitment in hiring fresher is purely based on the criteria of the student’s skill set capacity in terms of “Deployment” instead of “Employment”</li>
                </ul>

{/*                 <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Placed Student List</span> </h4>
                <table className="table">
      <thead style={{background:'#0098fe', color:'white', fontWeight:'700'}}>
        <tr >
          <th>Year</th>
          <th>Name</th>
          <th>Course</th>
          <th>Company</th>
        </tr>
      </thead>
      <tbody>
        {placed.map((placement, index) => (
          <React.Fragment key={index}>
            <tr >
              <td rowSpan={placement.data.length}>{placement.year}</td>
              <td>{placement.data[0].name}</td>
              <td>{placement.data[0].course}</td>
              <td>{placement.data[0].company}</td>
            </tr>
            {placement.data.slice(1).map((item, i) => (
              <tr key={i}>
                <td>{item.name}</td>
                <td>{item.course}</td>
                <td>{item.company}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table> */}
            </div>

        </div>
    )
}

export default Placement