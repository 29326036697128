import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Trans from '../../Assets/Transport.jpg'

const Transport = () => {
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="Transport" hide={true} />

            <div className='container-md mb-5'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Transport</span> </h2>

                <div className="row m-0  mt-5">
                    <div className="col-lg-5 mb-3 col-sm-12 d-flex align-items-center justify-content-center">
                        <img width='100%' className='rounded-3' style={{border:'4px solid #0098fe'}} src={Trans} alt="" />
                    </div>
                    <div className="col-lg-7 p-0 col-sm-12">
                        <p className='mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '500', lineHeight: '32px' }}>
                        The college transportation system is an integral part of our commitment to providing a seamless and safe commuting experience for students. Here are ten lines highlighting the key features of our college transport services:                       
                        Our transport network covers various routes, ensuring convenient pick-up and drop-off points for students across different locations.
                        Our drivers are experienced and undergo regular training to prioritize the safety of students, following all traffic regulations and maintaining a secure travel environment.
                         </p>
                   
                    </div>
                </div>
                <p className='mb-3' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '500', lineHeight: '32px' }}>
                We adhere strictly to safety standards, implementing measures such as regular vehicle maintenance, emergency response protocols, and adherence to COVID-19 safety guidelines.
                A well-organized and timely schedule is maintained to ensure students arrive punctually for classes, exams, and other college-related activities.
                The buses are designed with comfortable seating arrangements, ensuring a pleasant travel experience for students during their daily commute.
                  </p>

            </div>


        </div>
    )
}

export default Transport