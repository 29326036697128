import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'

const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Contact Us" hide={true} />

            <div className='container-md mb-5'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Contact Us</span> </h2>



                <div className="row m-0 my-5 ">
                    <div className="col-lg-6 col-sm-12">
                        <div style={{ background: '#0098fe', minHeight:'200px' }} className='mt-5  parentdiv rounded-3 py-5 d-flex align-items-center justify-content-between flex-column'>
                            <div className='p-3 rounded-circle conHover' style={{ marginTop: '-90px', border: '5px solid white ' }}>
                                <i style={{ color: 'white', fontSize: '32px' }} class='fa-solid fa-location-dot'></i>
                            </div>

                            <h5 className='m-0 py-2 text-center conHovers' style={{ fontWeight: '700' }}>Ranipettai Institute of Technology College</h5>
                            <h6 className='m-0 py-2 text-center' style={{ color: 'white', fontWeight: '700' }}>
                            Thenkadappanthangal, Walaja(TK), Ranipettai - 632513
                            </h6>
                            
                        </div>

                        <div style={{ background: '#0098fe' , minHeight:'200px'}} className='mt-5 parentdiv rounded-3 py-5 d-flex align-items-center justify-content-between flex-column'>
                            <div className='p-3 rounded-circle conHover' style={{ marginTop: '-90px', border: '5px solid white ' }}><i style={{ color: 'white', fontSize: '32px' }} class='fa-solid fa-envelope'></i> </div>

                            <h5 className='m-0 py-2 conHovers' style={{ fontWeight: '700' }}>E-Mail</h5>
                            <h6 className='m-0 py-2' style={{ color: 'white', fontWeight: '700' }}>ritthen30959@yahoo.co.in</h6>
                            <h6 className='m-0 py-2' style={{ color: 'white', fontWeight: '700' }}> 388ritexamcell@gmail.com</h6>
                            <h6 className='m-0 py-2' style={{ color: 'white', fontWeight: '700' }}> ritthen30959@gmail.com</h6>

                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div style={{ background: '#0098fe', minHeight:'200px' }} className='mt-5  parentdiv rounded-3 py-5 d-flex align-items-center justify-content-between flex-column'>
                            <div className='p-3 rounded-circle conHover' style={{ marginTop: '-90px', border: '5px solid white ' }}><i style={{ color: 'white', fontSize: '32px' }} class='fa-solid fa-phone'></i> </div>

                            <h5 className='m-0 py-2 conHovers' style={{ fontWeight: '700' }}>Contact Number</h5>
                            <h6 className='m-0 py-2' style={{ color: 'white', fontWeight: '700' }}>04172-299502</h6>
                            <h6 className='m-0 py-2' style={{ color: 'white', fontWeight: '700' }}>9443846666</h6>

                        </div>

                        <div style={{ background: '#0098fe', minHeight:'200px' }} className='mt-5 parentdiv rounded-3 py-5 d-flex align-items-center justify-content-between flex-column'>
                            <div className='p-3 rounded-circle conHover' style={{ marginTop: '-90px', border: '5px solid white ' }}><i style={{ color: 'white', fontSize: '32px' }} class='fa-solid fa-globe'></i> </div>

                            <h5 className='m-0 py-2 conHovers' style={{ fontWeight: '700' }}>Website</h5>
                            <h6 className='m-0 py-2' style={{ color: 'white', fontWeight: '700' }}></h6>
                        </div>
                    </div>

                </div>

                <div className="row m-0 my-3">
                    <div className="col-lg-6 col-sm-12">
                        <h4 className='m-0 py-2 text-center' style={{ color: '#0098fe', fontWeight: '700' }}><span style={{ borderBottom: '1px solid #393185' }}>Location </span> </h4>
                        <div className='mb-3' style={{ position: 'relative', overflow: 'hidden', paddingTop: '56.25%' }}>
                            <iframe
                                title="Google Map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1683.011788670757!2d79.39239999977802!3d12.911328370892976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52cad877a1f50d%3A0xde174d9f2fd22e09!2sRIT%20-%20polytechnic%20college!5e0!3m2!1sen!2sin!4v1706163897769!5m2!1sen!2sin"
                                className='rounded-2 shadow'
                                width="100%"
                                height="100%"
                                style={{ position: 'absolute', top: 0, left: 0, border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <h4 className='m-0 py-2 text-center' style={{ color: '#0098fe', fontWeight: '700' }}><span style={{ borderBottom: '1px solid #393185' }}>Drop Your Queries Here </span> </h4>
                        <form action="">
                            <div className="row mt-3 ">
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="Fname" className='CForm'>First Name *</label> <br />
                                    <input type="text" required style={{ borderColor: '#393185', border: '2px solid', outline: '0px' }} name="Fname" className='px-3 w-100 rounded-3' />
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="Fname" className='CForm'>Last Name</label> <br />
                                    <input type="text" style={{ borderColor: '#393185', border: '2px solid', outline: '0px' }} name="Lname" className='px-3 w-100 rounded-3' />
                                </div>
                            </div>

                            <div className="row mt-3 ">
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="Fname" className='CForm'>Mobile Number *</label> <br />
                                    <input type="text" required style={{ borderColor: '#393185', border: '2px solid', outline: '0px' }} name="Fname" className='px-3 w-100 rounded-3' />
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="Fname" className='CForm'>Email Address *</label> <br />
                                    <input type="text" required style={{ borderColor: '#393185', border: '2px solid', outline: '0px' }} name="Fname" className='px-3 w-100 rounded-3' />
                                </div>
                            </div>

                            <div className="row mt-3 ">
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="Fname" className='CForm'>Subject *</label> <br />
                                    <input type="text" required style={{ borderColor: '#393185', border: '2px solid', outline: '0px' }} name="Fname" className='px-3 w-100 rounded-3' />
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <label htmlFor="Fname" className='CForm'>Message *</label> <br />
                                    <textarea type="text" required style={{ borderColor: '#393185', border: '2px solid', outline: '0px' }} name="Fname" className='px-3 w-100 rounded-3' > </textarea>
                                </div>
                            </div>

                            <button type='submit' style={{ background: '#0098fe', color: 'white' }} className='rounded-3 mt-3 btn text-center w-100'>Submit</button>
                        </form>
                        <hr />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Contact