import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Pages/Home';
import Footer from './components/Footer';
import About from './components/Pages/About';
import Overview from './components/Pages/Overview';
import VissionMission from './components/Pages/VissionMission';
import KanchiTrust from './components/Pages/KanchiTrust';
import PrinchipleMess from './components/Pages/PrinchipleMess';
import { RankHolders } from './components/Pages/RankHolders';
import ECE from './components/Pages/ECE';
import Civil  from './components/Pages/Civil';
import NSS from './components/Pages/NSS';
import Youth from './components/Pages/Youth';
import Yoga from './components/Pages/Yoga';
import AntiRaggingCell from './components/Pages/AntiRaggingCell';
import Infrastructure from './components/Pages/Infrastructure';
import Library from './components/Pages/Library';
import Transport from './components/Pages/Transport';
import Placement from './components/Pages/Placement';
import News from './components/Pages/News';
import Gallery from './components/Pages/Gallery';
import Admission from './components/Pages/Admission';
import Contact from './components/Pages/Contact';
import AICTE from './components/Pages/AICTE';
import DEEE from './components/Pages/DEEE';
import ME from './components/Pages/ME';
import CE from './components/Pages/CE';
import Automobile from './components/Pages/Automobile';
import { useLayoutEffect } from 'react';
import ChairmanMsg from './components/Pages/ChairmanMsg';

function App() {
  useLayoutEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <div className="App">
      <Navbar />
      <div style={{ minHeight: '70vh' }} >
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about-us' element={<About />}></Route>
          <Route path='/overview' element={<Overview />}></Route>
          <Route path='/vision-mission' element={<VissionMission />}></Route>
          {/* <Route path='/kanchi_krishna_educational_trust' element={<KanchiTrust />}></Route> */}
          <Route path='/principal-message' element={<PrinchipleMess />}></Route>
          <Route path='/chairman-message' element={<ChairmanMsg />}></Route>

{/*           <Route path='/rank-holders' element={<RankHolders />}></Route> */}          
          
          <Route path='/electrical_electronics_engineering' element={<DEEE />}></Route>
          <Route path='/electronics-communication-engineering' element={<ECE />}></Route>
          <Route path='/mechanical_engineering' element={<ME />}></Route>
          <Route path='/computer_engineering' element={<CE />}></Route>
          <Route path='/automobile_engineering' element={<Automobile />}></Route>
          <Route path='/civil_engineering' element={<Civil />}></Route>
         


          {/* Co-curicular Activities*/}
          <Route path='/nss' element={<NSS />}></Route>
          <Route path='/youth_red_cross' element={<Youth />}></Route>
          <Route path='/yoga_club' element={<Yoga />}></Route>
          <Route path='/anti_ragging_cell' element={<AntiRaggingCell />}></Route>

          {/* Facilities*/}
          <Route path='/infrastructure' element={<Infrastructure />}></Route>
          <Route path='/library' element={<Library />}></Route>
          <Route path='/transport' element={<Transport />}></Route>

          {/* Activities */}
          <Route path='/placement' element={<Placement />}></Route>
          <Route path='/latest_news' element={<News />}></Route>
          <Route path='/gallery' element={<Gallery />}></Route>
          {/* <Route path='/admission' element={<Admission />}></Route> */}
          <Route path='/contact' element={<Contact />}></Route>
         {/*  <Route path='/aicte' element={<AICTE />}></Route> */}


        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
