import React, { useLayoutEffect, useState } from 'react'
import FixedHeader from '../FixedHeader'
import PhotoAlbum from 'react-photo-album'
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Lightbox from 'yet-another-react-lightbox';
import img1 from '../../Assets/Gallery/img1.jpg'
import img2 from '../../Assets/Gallery/img2.jpg'
import img3 from '../../Assets/Gallery/img3.jpg'
import img4 from '../../Assets/Gallery/img4.jpg'
import img5 from '../../Assets/Gallery/img5.jpg'
import img6 from '../../Assets/Gallery/img6.jpg'
import img7 from '../../Assets/Gallery/img7.jpg'
import img8 from '../../Assets/Gallery/img8.jpg'
import img9 from '../../Assets/Gallery/img9.jpg'
import img10 from '../../Assets/Gallery/img10.jpg'
import img11 from '../../Assets/Gallery/img11.jpg'
import img12 from '../../Assets/Gallery/img12.jpg'
import img13 from '../../Assets/Gallery/img13.jpg'
import img14 from '../../Assets/Gallery/img14.jpg'
import img15 from '../../Assets/Gallery/img15.jpg'
import img16 from '../../Assets/Gallery/img16.jpg'
import img17 from '../../Assets/Gallery/img17.jpg'
import img18 from '../../Assets/Gallery/img18.jpg'
import img19 from '../../Assets/Gallery/img19.jpg'
import img20 from '../../Assets/Gallery/img20.jpg'
import img21 from '../../Assets/Gallery/img21.jpg'
import img22 from '../../Assets/Gallery/img22.jpg'
import img23 from '../../Assets/Gallery/img23.jpg'
import img24 from '../../Assets/Gallery/img24.jpg'
import img25 from '../../Assets/Gallery/img25.jpg'
import img26 from '../../Assets/Gallery/img26.jpg'
import img27 from '../../Assets/Gallery/img27.jpg'
import img28 from '../../Assets/Gallery/img28.jpg'
import img29 from '../../Assets/Gallery/img29.jpg'
import img30 from '../../Assets/Gallery/img30.jpg'

const Gallery = () => {
    const [index, setIndex] = useState(-1);

    const photos = [
        { id: 1, src: img1, width: 1080, height: 900 },
        { id: 2, src: img2, width: 1080, height: 900 },
        { id: 3, src: img3, width: 1080, height: 900 },
        { id: 4, src: img4, width: 1080, height: 900 },
        { id: 5, src: img5, width: 1080, height: 900 },
        { id: 6, src: img6, width: 1080, height: 900 },
        { id: 7, src: img7, width: 1080, height: 900 },
        { id: 8, src: img8, width: 1080, height: 900 },
        { id: 9, src: img9, width: 1080, height: 900 },
        { id: 10, src: img10, width: 1080, height: 900 },
        { id: 11, src: img11, width: 1080, height: 900 },
        { id: 12, src: img12, width: 1080, height: 900 },
        { id: 13, src: img13, width: 1080, height: 900 },
        { id: 14, src: img14, width: 1080, height: 900 },
        { id: 15, src: img15, width: 1080, height: 900 },
        { id: 16, src: img16, width: 1080, height: 900 },
        { id: 17, src: img17, width: 1080, height: 900 },
        { id: 18, src: img18, width: 1080, height: 900 },
        { id: 19, src: img19, width: 1080, height: 900 },
        { id: 20, src: img20, width: 1080, height: 900 },
        { id: 21, src: img21, width: 1080, height: 900 },
        { id: 22, src: img22, width: 1080, height: 900 },
        { id: 23, src: img23, width: 1080, height: 900 },
        { id: 24, src: img24, width: 1080, height: 900 },
        { id: 25, src: img25, width: 1080, height: 900 },
        { id: 26, src: img26, width: 1080, height: 900 },
        { id: 27, src: img27, width: 1080, height: 900 },
        { id: 28, src: img28, width: 1080, height: 900 },
        { id: 29, src: img29, width: 1080, height: 900 },
        { id: 30, src: img30, width: 1080, height: 900 },
    ]

    
    return (
        <div>
            <FixedHeader title="Gallery" hide={true} />
<div className='container-md'> 
            <div className=' mb-5 '>
                <h2 className='py-2  text-center mt-2' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Gallery</span> </h2>
            </div>

            <div className="lg-react-element mb-5">

                <PhotoAlbum
                    photos={photos}
                    
                    layout="rows"
                    targetRowHeight={200}
                    onClick={({ index }) => setIndex(index)}
                />

                <Lightbox
                    slides={photos}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                    overlayStyles={{
                        background: 'rgba(10, 10, 10, 1.3)',
                    }}
                />

            </div>
            </div>
        </div>
    )
}

export default Gallery