import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'

const About = () => {

    const highlights1 = [
        { code: 'fa-solid fa-chalkboard-user', title: 'Eminent and well experienced faculty' },
        { code: 'fa-solid fa-pen-clip', title: 'MoUs on important issues such as Research, Academic/Cultural Exchange and industry interaction' },
        { code: 'fa-solid fa-user-tie', title: 'Career Guidence Call' },
        { code: 'fa-solid fa-user-gear', title: 'Dedicated Placement' },

    ]

    const highlights2 = [
        { code: 'fa-solid fa-universal-access', title: 'Value Addition in terms of Employment and Higher studies ' },
        { code: 'fa-solid fa-certificate', title: 'ISO Certified' },
        { code: 'fa-solid fa-industry', title: 'Entrepreneurship cell' },
        { code: 'fa-solid fa-diagnoses', title: 'Research Facility' },

    ]
    useLayoutEffect(()=>{
        window.scrollTo(0,0)
      })
    return (
        <div>
            <FixedHeader title="About Us" />

            <div className='container-lg mb-5'>
                <p style={{ textAlign: 'justify', color: '#393185', fontSize: '14px', fontWeight: '600', lineHeight: '32px' }}>

                Ranipet Institute of Technology Walaja, is located in Tamilnadu. The education institution was established in the year 1995. The College was affiliated to Directorate of Technical Education, Chennai. It is approved by AICTE (All India Council for Technical Education).</p>
                <h3 className='py-2 text-center' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '4px solid #FF9800' }}>Highlights </span> </h3>

                <div className="row mt-3">
                    <div className="col-lg-6 col-sm-6">
                        {highlights1.map((highlight, index) => (
                            <div key={index} className='my-3 py-3 rounded-2 d-flex flex-column align-items-center' style={{background:'#0098fe', minHeight:'200px'}}>
                              <div className='p-3 rounded-circle' style={{background:'#FF9800', border:'5px solid white '}}><i style={{color: '#393185', fontSize:'32px'}} class={highlight.code}></i> </div>  
                               
                                <h6 className='px-5 text-center pt-3 text-light' style={{fontWeight:'700'}}>{highlight.title}</h6>
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        {highlights2.map((highlight, index) => (
                            <div key={index} className='my-3 py-3 rounded-2 d-flex flex-column align-items-center' style={{background:'#0098fe', minHeight:'200px'}}>
                              <div className='p-3 rounded-circle' style={{background:'#FF9800', border:'5px solid white '}}><i style={{color: '#393185', fontSize:'32px'}} class={highlight.code}></i> </div>  
                               
                                <h6 className='px-5 text-center pt-3 text-light' style={{fontWeight:'700'}}>{highlight.title}</h6>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

        </div>
    )
}

export default About