import React, { useLayoutEffect } from 'react'
import FixedHeader from '../FixedHeader'
import Logo from '../../Assets/RIT COLLEGE LOGO.png'

const ECE = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <div>
            <FixedHeader title="Diploma in Electronics and Communication Engineering" hide={true} />

            <div className='container-md'>
                <h2 className='py-2  text-center mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ lineHeight: window.innerWidth < 800 ? '45px' : '' }}>Diploma in Electronics and Communication Engineering</span> </h2>
                <h4 className='py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Introduction</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    Welcome to our vibrant educational community, where innovation meets tradition. Committed to nurturing excellence, we provide a transformative learning experience that prepares students for success in a dynamic global landscape.                         </p>



                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>History of the Department</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>

                    The history of Diploma in Electronics & Communication Engineering dates back to the mid-20th century, paralleling the rapid evolution of electronics and communication technologies. As the demand for skilled professionals in these fields grew, educational institutions introduced specialized diploma programs to provide foundational knowledge. These early programs focused on analog electronics, communication systems, and basic electrical principles.                    </p>

                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Vision and Mission</span> </h4>

                <div className="row m-0 mt-5">
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h2>
                                    <p className='pt-2 text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Electronics & Communication Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR VISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        To be a pioneering center for Electronics & Communication Engineering education, shaping future leaders and innovators.                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="flip-container mt-5">
                            <div className="flip-box" style={{ height: '280px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Electronics & Communication Engineering</p>
                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR MISSION</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        We are dedicated to fostering a learning environment that empowers students with technical expertise, ethical values, and a passion for continuous growth.                                       </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="flip-container mt-3" >
                            <div className="flip-box" style={{ height: '600px' }}>
                                <div className="front d-flex flex-column align-items-center justify-content-center" style={{ background: '#393185' }}>
                                    <img width='100px' className='rounded-circle ' src={Logo} alt="" />

                                    <h2 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h2>
                                    <p className='pt-2  text-center' style={{ fontWeight: '700', color: 'white' }}>Diploma in Electronics & Communication Engineering</p>

                                </div>
                                <div className="back d-flex flex-column align-items-center justify-content-center" >
                                    <h4 style={{ fontWeight: '700', color: '#FF9800' }}>OUR GOAL</h4>
                                    <p style={{ fontWeight: '700', fontSize: "13px", color: 'white', lineHeight: '28px', textAlign: 'justify' }} className='px-4'>
                                        <ul>
                                            <li> Strive for continuous improvement in academic programs to ensure students receive the highest quality education.</li>
                                            <li> Foster a culture of research and innovation, encouraging both faculty and students to contribute to advancements in the field.</li>
                                            <li> Strengthen ties with industry partners to provide students with real-world exposure and enhance their employability.</li>
                                            <li> Aim for the holistic development of students by promoting extracurricular activities, leadership skills, and ethical values.</li>

                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <h4 className='mt-4 py-2 text-start mt-4' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Department Library</span> </h4>

                <p className=' mb-5' style={{ textAlign: 'justify', color: '#393185', fontSize: '15px', fontWeight: '600', lineHeight: '32px' }}>
                    The Department Library serves as a reservoir of knowledge, offering a curated collection of resources tailored to Electronics & Communication Engineering. With state-of-the-art facilities, it provides an immersive learning space for students to explore, research, and enhance their academic pursuits.                     </p>

                <h4 className='mt-2 py-2 text-start ' style={{ color: '#0098fe', fontWeight: '700' }}> <span className='pb-2' style={{ borderBottom: '3px solid #393185' }}>Achievements</span> </h4>

                <div>
                    <div className='d-flex align-items-center justify-content-center'> <i style={{ color: 'white', fontSize: '50px' }} class="acHover rounded-circle p-3 fa-solid fa-trophy"></i>  </div>

                    <div className='mt-3 d-flex align-items-center justify-content-center '>
                        <p className=' shadow rounded-3  mb-5 p-3 w-75' style={{ textAlign: 'center', color: '#393185', fontSize: '16px', fontWeight: '700', lineHeight: '32px' }}>

                            The Diploma in Electronics & Communication Engineering program boasts a legacy of success. Graduates have not only excelled in their professional journeys but have also contributed significantly to advancements in the field.                             </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ECE